import React,{useState} from 'react'
import Minus from "../../../../images/yard_assets/remove_icon.svg"
import Plus from "../../../../images/yard_assets/add_icon.svg"
const Add = ({callbackAdd}) => {
    const [show,setShow]=useState(true)
    const [value,setValue]=useState(1)
    const addwashFoodSer=()=>{
        setShow(false)
        callbackAdd(value)
    }
    const decrementHandler=()=>{
        if(value===1){
            setValue(1)
            callbackAdd(1)
        }else{
            setValue(value-1)
            callbackAdd(value-1)
        }
    }
    const incrementHandler=()=>{
        setValue(value+1)
        callbackAdd(value+1)
    }
  return (
    show ?<div className='wasg_food_add' onClick={addwashFoodSer}>add</div>
    :
    <div className='wash_inc_de'>
    <img src={Minus} alt="incr icon"  onClick={decrementHandler}/>  
    <p>{value}</p>  
    <img src={Plus} alt="decr icon" onClick={incrementHandler}/>    
    </div>
                                    
  )
}

export default Add